<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('administration.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('administration.table.fields.role') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >&nbsp;</b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-button
            variant="primary"
            class="float-right"
            @click="exportUsers"
          >
            <span class="text-nowrap">Export</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      roleOptions: [],
    }
  },
  computed: {
    exportedLink() {
      return `${process.env.VUE_APP_API_URL}/export/users.xlsx`
    },
  },
  beforeCreate() {
    this.$http.get('/api/roles').then(response => {
      const { data } = response.data

      this.roleOptions = this.$options.filters.transformForVSelect(data, 'name', 'title')
    })
  },
  methods: {
    exportUsers() {
      this.$http.get('/api/usersExcel')
        .then(() => {
          this.createAndDownloadBlobFile()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    createAndDownloadBlobFile() {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        link.setAttribute('href', this.exportedLink)
        link.setAttribute('download', 'users.xlsx')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
